/* eslint-disable import/no-import-module-exports */
import { hydrateRoot } from 'react-dom/client';
import { document, window } from '../../../js/globals';
import MySurpriseHeaderWidgetApp from './Components/App/MySurpriseHeaderWidgetApp';

const container = (document as Document).getElementById('mysurprise-header-app');

const { MYSURPRISE_HEADER_WIDGET_STATE } = window as Window;

if (container && MYSURPRISE_HEADER_WIDGET_STATE) {
    hydrateRoot(container, <MySurpriseHeaderWidgetApp preloadedState={MYSURPRISE_HEADER_WIDGET_STATE}/>);
}
