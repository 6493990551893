import type { FC, PropsWithChildren } from 'react';
import { createContext } from 'react';
import TranslationProvider from '../../../general/Translation/TranslationProvider';
import type { MySurpriseHeaderWidgetState } from '../../../../ssr/src/Outlet/MySurpriseHeaderWidget/MySurpriseHeaderWidgetSchema';

interface Props {
    state: MySurpriseHeaderWidgetState;
}

export const MySurpriseHeaderWidgetStateContext = createContext<MySurpriseHeaderWidgetState>({} as unknown as MySurpriseHeaderWidgetState);

const StateProvider: FC<PropsWithChildren<Props>> = ({ children, state }) => (
    <MySurpriseHeaderWidgetStateContext.Provider value={state}>
        <TranslationProvider dictionary={state.translations}>
            {children}
        </TranslationProvider>
    </MySurpriseHeaderWidgetStateContext.Provider>
);

export default StateProvider;
