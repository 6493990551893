import type { FC } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../../../../../mysurprise/Shared/Provider/AuthenticationProvider/AuthenticationProvider';
import useLogout from '../../../../../../mysurprise/Shared/Hooks/UseLogout';
import styles from '../../AccountButton.module.scss';
import Icon from '../../../../../../../stories/Atoms/Icon/Icon';
import { MySurpriseHeaderWidgetStateContext } from '../../../../Provider/StateProvider';
import useTranslate from '../../../../../../general/Translation/hooks/UseTranslate';

interface Props {
    hideSubtitle?: boolean;
}

const AuthorizedMenuContent: FC<Props> = ({ hideSubtitle = false }) => {
    const { hasPersistedData, isAuthenticating, user } = useContext(AuthContext);
    const { urls } = useContext(MySurpriseHeaderWidgetStateContext);
    const translate = useTranslate();

    const logout = useLogout();

    if (!hasPersistedData && isAuthenticating) {
        return null;
    }

    return (
        <>
            {!hideSubtitle && <span className={styles.subtitle}>{translate('mysurprise_header_widget', 'welcome_title')}</span>}
            <h2 className={styles.name}>{user?.name}</h2>

            <div className={styles.actions}>
                <a href={urls.myAccount}>
                    <div className={styles.action}>
                        <div className={styles.icon}><Icon name='fa-user' /></div>
                        {translate('mysurprise_header_widget', 'my_account_link')}
                    </div>
                </a>

                <a href={urls.myMoments}>
                    <div className={styles.action}>
                        <div className={styles.icon}><Icon name="fa-calendar"/></div>
                        {translate('my_moments', 'title')}
                    </div>
                </a>

                <a href={urls.myOrders}>
                    <div className={styles.action}>
                        <div className={styles.icon}><Icon name='fa-box' /></div>
                        {translate('mysurprise_header_widget', 'my_orders_link')}
                    </div>
                </a>

                <a href={urls.myDiscounts}>
                    <div className={styles.action}>
                        <div className={styles.icon}><Icon name='fa-badge-percent'/></div>
                        {translate('mysurprise_header_widget', 'my_discounts_link')}
                    </div>
                </a>

                <a href={urls.personalInfo}>
                    <div className={styles.action}>
                        <div className={styles.icon}><Icon name='fa-address-card'/></div>
                        {translate('mysurprise', 'personal_information')}
                    </div>
                </a>

                <div className={styles.action} onClick={() => logout()}>
                    <div className={styles.icon}><Icon name='fa-arrow-right-from-bracket' /></div>
                    {translate('mysurprise_header_widget', 'log_out_button')}
                </div>
            </div>
        </>
    );
};

export default AuthorizedMenuContent;
